export type AddonDescriptor = {
  id: string;
  icon: string;
  title: string;
  summary: string;
  plan: Plan;
  login_url?: string;
  has_ui: boolean;
  scopes: string[];
  terms_url?: string;
  description?: string;
  setup_guide?: {
    notification: string;
    instructions: { type: string; description: string; card_content: string }[];
  };
};

export type Addon = {
  id: AddonId;
  icon: string;
  isEnabled?: boolean;
  title: string;
  summary: string;
  plan: Plan;
  loginUrl?: string;
  hasUi: boolean;
  scopes: string[];
  termsUrl?: string;
  documentationUrl?: string;
  description?: string;
  setupGuide?: {
    notification: string;
    instructions: AddonSetupInstruction[];
  };
};

export enum AddonId {
  TestReports = 'addons-testing',
  RollingBuilds = 'addons-rolling-build',
}

export type AddonSetupInstruction = {
  type: string;
  description: string;
  cardContent: string;
  iconUrl?: string;
};

type Plan = {
  name: string;
};
